import Offcanvas from 'bootstrap/js/dist/offcanvas';

(function(w,d){

    w.loadCss = function(url){
        let css = document.createElement('link');
        css.rel = 'stylesheet';
        css.media = 'all';
        css.href = url;
        document.getElementsByTagName('head')[0].appendChild(css);
    }

    w.loadCss('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css');

    w.lazyFunctions = {
        swiperColunistas: async el => {
            const {swiperColunistas} = await import('./swiperColunistas');
            return Promise.resolve(swiperColunistas(el));
        },
        swiperImagens: async el => {
            const {swiperImagens} = await import('./swiperImagens');
            return Promise.resolve(swiperImagens(el));
        },
        faleconosco: async el => {
            const {FalEonosco} = await import('./faleconosco');
            return Promise.resolve(FalEonosco(el));
        }
    };

    w.lazyLoadOptions = {
        unobserve_entered: true,
        elements_selector: ".lazy, iframe[data-src], [class^='fb-'], .instagram-media, .twitter-tweett, [class^='sharethis-inline-share-buttons']",
        callback_enter: (element) => {
            if(element.hasAttribute('data-lazy-function')) {
                let lazyFunctionName = element.getAttribute("data-lazy-function");
                let lazyFunction = w.lazyFunctions[lazyFunctionName];
                if (!lazyFunction) return;
                return lazyFunction(element);
            } else {
                window.dataLayer = window.dataLayer || [];
                let classList = String(element.classList);
                let eventName = '';
                let removeEntered = false;

                if(classList.match(/fb-*/gi)) {
                    eventName = 'LoaderFacebookSDK';
                    removeEntered = true;
                } else if(classList.match(/instagram-media/gi)) {
                    eventName = 'LoaderInstagram';
                } else if(classList.match(/taboola/gi)) {
                    eventName = 'LoaderTaboolaFeed';
                } else if(classList.match(/twitter-tweet/gi)) {
                    eventName = 'LoaderTwitter';
                } else if(classList.match(/sharethis-inline-share-buttons/gi)) {
                    eventName = 'LoaderShareButtons';
                }

                if(eventName) {
                    console.log(eventName);
                    if(removeEntered) element.classList.remove('entered');
                    window.dataLayer.push({'event': eventName});
                }
            }
        }
    };
    
    w.addEventListener("LazyLoad::Initialized", event => w.lazyLoadInstance = event.detail.instance, false);

    w.canvasShowElements = () => {

    };

    w.canvasHideElements = () => {

    };

    const offcanvasSearch = document.getElementById('offcanvasBusca');
    if(offcanvasSearch) {

        offcanvasSearch.addEventListener('show.bs.offcanvas', function () {
            return w.canvasHideElements();
        });

        offcanvasSearch.addEventListener('shown.bs.offcanvas', function () {
            document.querySelector('#q').focus();
        });
    
        offcanvasSearch.addEventListener('hidden.bs.offcanvas', function () {
            document.querySelector('#q').value = '';
            return w.canvasShowElements();
        });
    }

    Promise.all([
        import('bootstrap/js/dist/offcanvas'),
        import('freezeui')
        // import('vanilla-lazyload')
    ])
    .then(() => {
        console.log('loaded');
    })
    .catch(error => {
        console.error('erro ao importar componente offcanvas', error)
    });

})(window,document);
